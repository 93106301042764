import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import H2 from 'components/new/typography/h2'
import { RegionPickerModal, SelectedRegionRibbon, useRegionPicker } from 'components/region-picker'
import { LoadingScreen, useLoadingScreen } from 'components/loading-screen'
import LocationsMapSearch from 'components/locations-map/locations-map-search'
import { Seo } from '~/components/seo'

const breadcrumbs = [
  {
    name: 'Hutson Inc',
    displayName: 'Home',
    link: '/',
  },
  {
    name: 'Service',
    link: '/service/',
  },
  {
    name: 'Turf Equipment Service Programs',
    link: '/service/turf-maintenance-agreements/',
  },
  {
    name: 'Small Lawn Mower Service Programs',
    link: '/service/turf-maintenance-agreements/small-lawn-mowers/',
  },
]

const SmallLawnMowersSMAPage = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      heroImage,
    },
  } = props
  const {
    region,
    setRegion,
    isOpen: regionPickerModalOpen,
    toggleModal: toggleRegionPickerModal,
  } = useRegionPicker()
  const { loading } = useLoadingScreen({ conditions: [region] })
  return (
    <Layout>
      <Seo title={`Small Lawn Mower Service Programs`} breadcrumbs={breadcrumbs} />

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Small Lawn Mower Service Programs'
        overlayOpacity={0.3}
      />

      <RegionPickerModal
        isOpen={regionPickerModalOpen}
        region={region}
        setRegion={setRegion}
        toggleModal={toggleRegionPickerModal}
      />

      <LoadingScreen loading={loading} message='Fetching service programs for your region...' />

      <div style={{ display: loading ? 'none' : 'block' }}>
        <SelectedRegionRibbon
          style={{ backgroundColor: '#eaebeb' }}
          region={region}
          toggleModal={toggleRegionPickerModal}
        />
        <Content kind='full'>
          <div style={{ height: '70vh', minHeight: '250px' }}>
            {region === 'north' ? (
              <iframe
                src='https://player.flipsnack.com?hash=NTk3NUFCREQ3NUUrdnRuZnZ6dmU0Nw=='
                title='Turf Service Maintenance Agreements - Michigan'
                width='100%'
                height='100%'
                seamless='seamless'
                scrolling='no'
                frameBorder='0'
                allowFullScreen
                allow='autoplay; clipboard-read; clipboard-write'
              ></iframe>
            ) : (
              <iframe
                src='https://player.flipsnack.com?hash=NTk3NUFCREQ3NUUrdnBpcTF1MTZyaA=='
                title='Turf Service Maintenance Agreements - South'
                width='100%'
                height='100%'
                seamless='seamless'
                scrolling='no'
                frameBorder='0'
                allowFullScreen
                allow='autoplay; clipboard-read; clipboard-write'
              ></iframe>
            )}
          </div>
        </Content>
      </div>
      <LightSection>
        <Content kind='full'>
          <H2>Contact your nearest service shop to learn more</H2>
        </Content>
        <LocationsMapSearch locations={allSanityLocationNodes} />
      </LightSection>
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

export const pageQuery = graphql`
  query smallLawnMowersSMAPageQuery {
    heroImage: file(
      relativePath: {
        eq: "service/maintenance-agreements/lawn-tractor-service-dsc02057-2-hero.jpg"
      }
    ) {
      ...FullWidthImage
    }
    allSanityLocation(
      filter: { retailLocation: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
  }
`

export default SmallLawnMowersSMAPage
